import AddonNotificationsService from 'ember-cli-notifications/services/notifications';
import { tracked } from '@glimmer/tracking';


export default class NotificationsService extends AddonNotificationsService {
  @tracked notifications = [];

  _handleDuplicateNotification(message, type, options = {}) {
    const existingNotification = this.notifications.find(
      (n) => n.message === message && n.type === type
    );

    if (existingNotification) {
      // If a notification with the same message and type exists, reset its timeout
      this._resetNotificationTimeout(existingNotification);
    } else {
      // If no duplicate exists, create a new notification
      const notification = super[type](message, options);
      this.notifications.push(notification);
    }
  }

  _resetNotificationTimeout(notification) {
    if (notification.clearTimer) {
      // Clear the existing timeout
      clearTimeout(notification.clearTimer);
    }

    // Set a new timeout for the notification
    notification.clearTimer = setTimeout(() => {
      this.removeNotification(notification);
    }, notification.timeout || 3000); // Default to 3 seconds if no timeout is provided
  }

  removeNotification(notification) {
    super.removeNotification(notification);
    this.notifications = this.notifications.filter((n) => n !== notification);
  }

  remove(...notifications) {
    notifications.forEach((n) => this.removeNotification(n));
  }

  success(message, options = {}) {
    console.log('success');
    this._handleDuplicateNotification(message, 'success', options);
  }

  error(message, options = {}) {
    console.log('error');
    this._handleDuplicateNotification(message, 'error', options);
  }

  info(message, options = {}) {
    console.log('info');
    this._handleDuplicateNotification(message, 'info', options);
  }

  warning(message, options = {}) {
    console.log('warning');
    this._handleDuplicateNotification(message, 'warning', options);
  }
}
